/**************************************************
home.css
***************************************************/

.home {

header {
  background-image: url('/images/header.jpg');
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  margin-top: -40px;

  .intro-text {

    padding-top: 150px;
    padding-bottom: 100px;


    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.2em;
    }
    
    img {
      width: 100px;
      height: auto;
    }

    @media (min-width: 768px) {
      padding-top: 100px;
      padding-bottom: 150px;

      h1 {
        font-size: 2em;
      }
      h2 {
        font-size: 1.4em;
      }
      img {
        width: 120px;
        height: auto;
      }
    }

    @media (min-width: 992px) {
      padding-top: 150px;
      padding-bottom: 220px;

      h1 {
        font-size: 3em;
      }
      h2 {
        font-size: 1.5em;
      }
      img {
        width: 150px;
        height: auto;
      }
    }

    @media (min-width: 1200px) {

        padding-top: 150px;
        padding-bottom: 300px;

      h1 {
        font-size: 4em;
      }
      h2 {
        font-size: 2em;
      }
      img {
        width: auto;
      }
    }
  }

}



h1 {
  margin-bottom: 30px;
}

p, ul {
  font-size: 17.5px;
}

#about {
  .img-circle {
    border: 7px solid #f1f1f1;
  }
}


#pricing {

  ul {
    background: #FAFAFA;
    padding: 0;
    margin: 0;
  }

  .heading {
    background: #5c5c5c;
    margin-left: -10px;
  }

  ul li {
    list-style: none;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 400;
  }

  h2 {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 36px;
    color: #fff;
  }

  h3, span {
    color: #fff;
  }

  span {
    font-weight: 700;
    font-size: 18px;
  }

  ul li:nth-child(even) {
    background: #f5f5f5;
  }

  .plan-action {
    height: 40px;
  


    .btn-primary {
      background: #5c5c5c;
      border-radius: 4px;
      border: none;
      position: relative;
      padding: 5px 20px;
      color: #fff;
      margin-top: 5px;
    }


    .btn-primary:before {
      border-color: #E24F43 transparent transparent;
      border-radius: 5px 0 0 0;
      border-style: solid;
      border-width: 12px;
      bottom: -12px;
      content: "";
      height: 0;
      left: -9px;
      position: absolute;
      width: 0;
      z-index: -1;
    }

    .btn-primary:after {
      border-color: #E24F43 transparent transparent;
      border-radius: 5px 0 0 0;
      border-style: solid;
      border-width: 12px;
      bottom: -12px;
      content: "";
      height: 0;
      position: absolute;
      right: -9px;
      width: 0;
      z-index: -1;
    }

    .btn-primary:before, 
    .btn-primary:after {
      border-color: #424242 transparent transparent;
      right: -9px;
    }
  }
  
  .plan {
    position: relative;
    margin-bottom: 70px;
  }
  
  
  .color1 .heading,
  .color1 .plan-action .btn-primary {
    background-color: #7C786A;
  }

  .color2 .heading,
  .color2 .plan-action .btn-primary {
    background-color: #8DCDC1;
  }

  .color3 .heading,
  .color3 .plan-action .btn-primary,
  .color3 .heading h2,
  .color3 .heading span {
    background-color: #D3E397;
    color: #7C786A;
  }

  .color4 .heading,
  .color4 .plan-action .btn-primary,
  .color4 .heading h2,
  .color4 .heading span {
    background-color: #FFF5C3;
    color: #7C786A;
  }
  

}

/* avoid extra space between last section and footer on home */
section:last-child {
  margin-bottom: -60px;
}


}