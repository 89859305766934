/**************************************************
roadmap.css
***************************************************/

.road-map {
  
  .modal-lg {
    width: 80%;
  }
  
  .zoom {
    position: absolute;
    z-index: 100;
  }
  
  .zoom div {
    margin: 5px;
  }
  
}