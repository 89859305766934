/**************************************************
master.css
***************************************************/

.side-menu {
  @if $media == pdf {
    display: none;
  }

  ul {
    list-style: none;
    padding-left: 15px; 
  }

  ul li span.arrow {
    transition: .3s;
    cursor: pointer; 
  }

  ul > li.open > span.arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transition: .3s; 
  }

  ul li.hasSubmenu > ul {
    display: none;
  }

  ul.root {
    padding-left: 0; 
  }
  
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}




.note-editor {
  
  .panel-heading.note-toolbar {
    @if $media == pdf {
      display: none;
    }
  }
  
  .note-statusbar {
    @if $media == pdf {
      display: none;
    }
  }
  
  .note-editing-area .note-editable {
    @if $media == pdf {
      height: auto !important;
    }
  }
  
}


.exclude {
  @if $media == pdf {
    display: none;
  }
}



select {
  @if $media == pdf {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}




.description {
  max-height: 100px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}

.description.expanded {
  max-height: 2048px;
  transition: max-height 1s ease-in;
}

.description .read-more { 
  position: absolute; 
  bottom: -20px; 
  left: 0;
  width: 100%; 
  text-align: center; 
  margin: 0; padding: 30px 0; 
	
  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, white);
}

.description.expanded .read-more {
  display: none;
}