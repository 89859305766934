.development-strategy {

  ul.facts .list-group-item {
    border: none;
  }
  
  .label-default {
    background-color: #D3E397;
    color: inherit;
  }

}