/**************************************************
section.css
***************************************************/

.section {
  
  #chart-container {
    max-width: 800px;
  }
  
}
