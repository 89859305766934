/**************************************************
footer.css
***************************************************/

footer {
  @if $media == pdf {
    display: none;
  }
 
  margin-top: 60px;
  color: #ddd;
  
  
  a {
    color: #ddd;
  }

  a:hover {
    transition: color ease-in-out .2s;
  }

  a:focus,
  a:hover {
    color: #fff;
    background-color: transparent;
    text-decoration: none;
  }
  
}

#footer {
  background: #757575;
  border: 1px solid #555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3); 
  padding-top: 25px;
  padding-bottom: 15px;
}
