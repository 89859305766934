/**************************************************
general.css
***************************************************/

.fullscreen {
  
  .note-fs-misc,
  .note-fs-style,
  .note-fs-layout,
  .note-fs-insert {
    display: inline-block;
  }

  .note-misc,
  .note-style,
  .note-layout,
  .note-insert {
    display: none;
  }
  
}


.note-misc,
.note-style,
.note-layout,
.note-insert {
  display: inline-block;
}

.note-fs-misc,
.note-fs-style,
.note-fs-layout,
.note-fs-insert {
  display: none;
}


.sps-header {
  @if $media == pdf {
    display: none;
  }
  
  img.img-circle {
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.btn {
  @if $media == pdf {
    display: none;
  }
}

a {
  color: initial;
}

a.help {
  text-decoration: none;
}

@if $media != pdf {
a.help:after {
  font-family: 'fontawesome';
  content: '\f05a';
}
}

a.help:hover { 
  text-decoration: none; 
  border-bottom:2px dashed; 
}


.transparent {
  color: transparent;
}

.page-title {
  text-transform: uppercase;
  margin-top: 90px;
}


section {
  padding-top: 50px;
  padding-bottom: 50px;
}

section:nth-child(odd) {
  background-color: #f7f7f7;
}

.back-light-gray {
  background-color: #f7f7f7;
}

.back-light-green {
  background-color: #8DCDC1;
}

.thumbnail.left {
  margin-right: 20px;
}

.navbar-inverse {
  opacity: .9;
  box-shadow: 0 1px 4px rgba(0,0,0,.5);
}



/* PDF specific */
div.alwaysbreak {
  page-break-before: always; 
}
div.nobreak:before { 
  clear:both; 
}
div.nobreak { 
  page-break-inside: avoid;
}


