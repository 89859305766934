/**************************************************
matrix.css
***************************************************/


.square-xs-1 {
    float: left;
    position: relative;
    padding-bottom: 8.33333333%;
}

.square-xs-2 {
    float: left;
    position: relative;
    padding-bottom: 16.66666667%;
}

.square-xs-3 {
    float: left;
    position: relative;
    padding-bottom: 25%;
}


.square-xs-4 {
    float: left;
    position: relative;
    padding-bottom: 33.33333333%;
}


.square-content {
    position: absolute;
    /* height: calc(100% - 1px); */
    /* width: calc(100% - 1px); */
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    /* background-color: #5b9dd9; */
    overflow: hidden;
    /* border-radius: 10px; */
    border: 1px solid;
    line-height: 1.1em;
}

.matrix {
  
  @if $media == pdf {
    width: 1024px;
    height: auto;
    margin-left: 30px;
  }
}

  .chart-container.bubble {
      margin: 15px;
      max-width: 100%;
  }

  .chart-container.bubble .row {
    margin-right: 15px;
  }


  $square_radius: 5px;
  $table_border_color: black;
  .chart-container.bubble {
    
    .square-content {
      border-color: #eee;
    }

    .row:first-child .square-content {
      border-top-color: $table_border_color;
    }

    .row:nth-last-child(4) .square-content {
      border-bottom-color: $table_border_color;
    }

    .row .square-xs-1:last-child .square-content,
    .row .square-xs-2:last-child .square-content,
    .row .square-xs-3:last-child .square-content,
    .row .square-xs-4:last-child .square-content {
      border-right-color: $table_border_color;
    }

    .row .square-xs-1:nth-child(2) .square-content,
    .row .square-xs-2:nth-child(2) .square-content,
    .row .square-xs-3:nth-child(2) .square-content,
    .row .square-xs-4:nth-child(2) .square-content {
      border-left-color: $table_border_color;
    }

    .row:first-child .square-xs-1:nth-child(2) .square-content,
    .row:first-child .square-xs-2:nth-child(2) .square-content,
    .row:first-child .square-xs-3:nth-child(2) .square-content,
    .row:first-child .square-xs-4:nth-child(2) .square-content {
      border-radius: $square_radius 0 0 0;
    }

    .row:nth-last-child(4) .square-xs-1:last-child .square-content,
    .row:nth-last-child(4) .square-xs-2:last-child .square-content,
    .row:nth-last-child(4) .square-xs-3:last-child .square-content,
    .row:nth-last-child(4) .square-xs-4:last-child .square-content {
      border-radius: 0 0 $square_radius 0;
    }

    .row:first-child .square-xs-1:last-child .square-content,
    .row:first-child .square-xs-2:last-child .square-content,
    .row:first-child .square-xs-3:last-child .square-content,
    .row:first-child .square-xs-4:last-child .square-content {
      border-radius: 0 $square_radius 0 0;
    }

    .row:nth-last-child(4) .square-xs-1:nth-child(2) .square-content,
    .row:nth-last-child(4) .square-xs-2:nth-child(2) .square-content,
    .row:nth-last-child(4) .square-xs-3:nth-child(2) .square-content,
    .row:nth-last-child(4) .square-xs-4:nth-child(2) .square-content {
      border-radius: 0 0 0 $square_radius;
    }

    .axis {
      @if $media == pdf {
        font-size: 0.6em;
      }
      @else {
        font-size: 0.75em;
      }
    }

    .axis .square-content {
        border: none;
    }

    .horiz.axis {
      text-align: center;
    }


    /* verticaly center vertical axis label */
    div.vert.axis > div > div {
      display: table;
      height: 100%;
      width: 100%;
    }
    div.vert.axis > div > div > div {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      width: 100%;

      padding-right: 5px;
      text-align: right;
    }


    .y-axis-label {
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: left top;
      margin-left: -30px;
    }

    .x-axis-label:after,
    .y-axis-label:after {
      font-family: 'fontawesome';
      content: '\f178';
    }


    .x-axis-label {
      padding-left: 0;
    }


    span.label.label-default {
      font-size: 0.7em;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
    
    
    
    
    .cell {
      text-align: center;
    }


    .square-content .title {
      text-align: center;
      font-weight: bold;
      font-size: 0.75em;
      
      a {
        color: initial;
      }
    }
    
    .vert.axis {
      width: 5%;
    }

  }
  
  /* we select the second elem because the first elem is hidden */
  .competition-item:nth-child(2) input,
  .competition-item:nth-child(2) select {
    background-color: #FCF8E3;
  }
  



.matrix.competitive-strategy {
  
}


.matrix.strategic-group-analysis {

}