/**************************************************
critical_evaluation_strategic_capability.css
***************************************************/

$border-radius: 5px;
$blue-color: #8DCDC1;
$red-color: #D3F3A7;
$purple-color: #9BA0D4;

.module.critical-evaluation-strategic-capability {

  #chart-container {
    max-width: 800px;
  }
  
  ul {
    padding: 0 5px;
  }
  
  .important.strong,
  .irrelevant.strong,
  .important.weak {
    padding: 10px;
    padding-bottom: 25px;
  }

  .important.strong {
    background-color: $blue-color;
    border-radius: $border-radius $border-radius 0 0;
  }

  .irrelevant.strong {
    background-color: $purple-color;
    border-radius: 0;
  }

  .important.weak {
    background-color: $red-color;
    border-radius: 0 0 $border-radius $border-radius;
  }
  
  .legend {
    text-align: center;
    
    h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  
  .form {
    margin-top: 50px;
  }
  
  .glyphicon-arrow-up, .glyphicon-arrow-down {
    -ms-transform: scale(2.0, 1.0);
    -webkit-transform: scale(2.0, 1.0);
    transform: scale(2.0, 1.0);
  }
 
}