/**************************************************
swot.css
***************************************************/

.module.swot .board { 
  margin: 15px;

  .spacer-top {
    margin-top: 20px;
  }
  
  
  .row {
    @if $media == html {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; 
    }
  }
  
  
  .row.main {
    @if $media == pdf {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; 
    }
  }
  

  ul.tags {
    padding: 0 5px;
    width: 100%;
  }

    ul.tags > li {
      @if $media == html {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 109px;
      }
    }

  .bootstrap-tagsinput{
    width: 100%;
    background-color: transparent;
  }

  [class^='col-'] {
    padding-bottom: 15px;
    padding-top: 15px;
  }


  $border-radius: 5px;
  $blue-color: #8DCDC1;
  $lightblue-color: #9BA0D4;
  $red-color: #FFCFAF;
  $purple-color: #FFE9AF;

  .strengths {
    border-radius: $border-radius 0 0 0;
    background-color: $blue-color;
  }

  .weaknesses {
    border-radius: 0 $border-radius 0 0;
    background-color: $red-color; 
  }

  .opportunities {
    background-color: $blue-color;
    @if $media == pdf {
      border-radius: 0 0 0 $border-radius;
    }
    @else {
      border-radius: $border-radius 0 0 0;
    }
  }

  .threats {
    background-color: $red-color;
    @if $media == pdf {
      border-radius: 0 0 $border-radius 0;
    }
    @else {
      border-radius: 0 0 0 $border-radius;
    }
  }

  .strengths.opportunities {
    border-radius: 0;
    background-color: $lightblue-color; //lighten($blue-color, 10%);
  }

  .weaknesses.opportunities {
    @if $media == pdf {
      border-radius: 0 0 $border-radius $border-radius;
    }
    @else {
      border-radius: 0;
    }
    background-color: $purple-color; 
  }

  .strengths.threats {
    @if $media == pdf {
      border-radius: $border-radius $border-radius 0 0;
    }
    @else {
      border-radius: 0;
    }
    background-color: $purple-color; 
  }

  .weaknesses.threats {
    @if $media == pdf {
      border-radius: 0;
    }
    @else {
      border-radius: 0 0 $border-radius 0;
    }
    background-color: $red-color; 
  }


}