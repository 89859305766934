/**************************************************
chart.css
***************************************************/

.chart-container {
  position: relative;
  width: 100% !important;
  height: auto !important;
  max-width: 400px;
}



.chart-legend {
    position: absolute;
    top: 0;


  li {
    list-style-type: none;
  }

    li span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

}